<template>
  <LoadingScreen v-if="loading" />
  <PageLayout v-else title="Poll">
    <template v-slot:header>
      <router-link to="/"><Button primary>Watch Stream</Button></router-link>
    </template>

    <div class="flex items-center justify-center my-8">
      <Spinner v-if="loading" class="text-red-500 icon-xl" />
      <Poll v-if="showPoll" />
    </div>
  </PageLayout>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

import PageLayout from "../layouts/PageLayout";
import Poll from "../components/Poll";
import Spinner from "../components/shared/Spinner";
import Button from "../components/shared/Button";
import LoadingScreen from "../components/LoadingScreen";
import { SUBSCRIBE_SETTINGS } from "../store/actions";

export default {
  components: { Button, Poll, PageLayout, Spinner, LoadingScreen },
  setup() {
    const store = useStore();

    store.dispatch(SUBSCRIBE_SETTINGS);

    return {
      loading: computed(() => store.state.loading.settings),
      showPoll: computed(
        () => !!store.state.settings.url && store.state.settings.live
      ),
    };
  },
};
</script>
