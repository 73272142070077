<template>
  <div class="flex items-center justify-center h-screen bg-gray-100">
    <Spinner class="text-red-500 icon-xl" />
  </div>
</template>

<script>
import Spinner from "./shared/Spinner";

export default {
  components: { Spinner },
};
</script>
